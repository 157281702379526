


















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question527',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        language: 'fr',
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q528_main.png';
    },
    imageAName() {
      return '/img/assignments/q528_choiceA.png';
    },
    imageBName() {
      return '/img/assignments/q528_choiceB.png';
    },
    imageCName() {
      return '/img/assignments/q528_choiceC.png';
    },
  },
};
